// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-page-js": () => import("./../../../src/Templates/AdminPage.js" /* webpackChunkName: "component---src-templates-admin-page-js" */),
  "component---src-templates-blog-detail-page-js": () => import("./../../../src/Templates/BlogDetailPage.js" /* webpackChunkName: "component---src-templates-blog-detail-page-js" */),
  "component---src-templates-blog-list-page-index-js": () => import("./../../../src/Templates/BlogListPage/index.js" /* webpackChunkName: "component---src-templates-blog-list-page-index-js" */),
  "component---src-templates-category-detail-js": () => import("./../../../src/Templates/CategoryDetail.js" /* webpackChunkName: "component---src-templates-category-detail-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/Templates/CategoryList.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-coupon-js": () => import("./../../../src/Templates/Coupon.js" /* webpackChunkName: "component---src-templates-coupon-js" */),
  "component---src-templates-custom-order-form-page-index-js": () => import("./../../../src/Templates/CustomOrderFormPage/index.js" /* webpackChunkName: "component---src-templates-custom-order-form-page-index-js" */),
  "component---src-templates-invoice-detail-index-js": () => import("./../../../src/Templates/InvoiceDetail/index.js" /* webpackChunkName: "component---src-templates-invoice-detail-index-js" */),
  "component---src-templates-invoice-list-index-js": () => import("./../../../src/Templates/InvoiceList/index.js" /* webpackChunkName: "component---src-templates-invoice-list-index-js" */),
  "component---src-templates-logistic-list-index-js": () => import("./../../../src/Templates/LogisticList/index.js" /* webpackChunkName: "component---src-templates-logistic-list-index-js" */),
  "component---src-templates-member-detail-page-index-js": () => import("./../../../src/Templates/MemberDetailPage/index.js" /* webpackChunkName: "component---src-templates-member-detail-page-index-js" */),
  "component---src-templates-member-list-page-index-js": () => import("./../../../src/Templates/MemberListPage/index.js" /* webpackChunkName: "component---src-templates-member-list-page-index-js" */),
  "component---src-templates-monthly-detail-index-js": () => import("./../../../src/Templates/MonthlyDetail/index.js" /* webpackChunkName: "component---src-templates-monthly-detail-index-js" */),
  "component---src-templates-monthly-member-list-index-js": () => import("./../../../src/Templates/MonthlyMemberList/index.js" /* webpackChunkName: "component---src-templates-monthly-member-list-index-js" */),
  "component---src-templates-order-detail-index-js": () => import("./../../../src/Templates/OrderDetail/index.js" /* webpackChunkName: "component---src-templates-order-detail-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-period-detail-page-js": () => import("./../../../src/Templates/PeriodDetailPage.js" /* webpackChunkName: "component---src-templates-period-detail-page-js" */),
  "component---src-templates-period-list-page-js": () => import("./../../../src/Templates/PeriodListPage.js" /* webpackChunkName: "component---src-templates-period-list-page-js" */),
  "component---src-templates-product-detail-page-index-js": () => import("./../../../src/Templates/ProductDetailPage/index.js" /* webpackChunkName: "component---src-templates-product-detail-page-index-js" */),
  "component---src-templates-product-list-page-index-js": () => import("./../../../src/Templates/ProductListPage/index.js" /* webpackChunkName: "component---src-templates-product-list-page-index-js" */),
  "component---src-templates-product-spec-dev-js": () => import("./../../../src/Templates/ProductSpecDev.js" /* webpackChunkName: "component---src-templates-product-spec-dev-js" */),
  "component---src-templates-product-spec-editor-index-js": () => import("./../../../src/Templates/ProductSpecEditor/index.js" /* webpackChunkName: "component---src-templates-product-spec-editor-index-js" */),
  "component---src-templates-product-spec-uploader-js": () => import("./../../../src/Templates/ProductSpecUploader.js" /* webpackChunkName: "component---src-templates-product-spec-uploader-js" */),
  "component---src-templates-promo-item-index-js": () => import("./../../../src/Templates/PromoItem/index.js" /* webpackChunkName: "component---src-templates-promo-item-index-js" */),
  "component---src-templates-promotion-index-js": () => import("./../../../src/Templates/Promotion/index.js" /* webpackChunkName: "component---src-templates-promotion-index-js" */),
  "component---src-templates-promotion-logistic-fee-js": () => import("./../../../src/Templates/PromotionLogisticFee.js" /* webpackChunkName: "component---src-templates-promotion-logistic-fee-js" */),
  "component---src-templates-refund-list-js": () => import("./../../../src/Templates/RefundList.js" /* webpackChunkName: "component---src-templates-refund-list-js" */),
  "component---src-templates-report-js": () => import("./../../../src/Templates/Report.js" /* webpackChunkName: "component---src-templates-report-js" */),
  "component---src-templates-request-upgrade-list-index-js": () => import("./../../../src/Templates/RequestUpgradeList/index.js" /* webpackChunkName: "component---src-templates-request-upgrade-list-index-js" */),
  "component---src-templates-return-application-list-js": () => import("./../../../src/Templates/ReturnApplicationList.js" /* webpackChunkName: "component---src-templates-return-application-list-js" */),
  "component---src-templates-statistics-js": () => import("./../../../src/Templates/Statistics.js" /* webpackChunkName: "component---src-templates-statistics-js" */),
  "component---src-templates-user-group-detail-js": () => import("./../../../src/Templates/UserGroupDetail.js" /* webpackChunkName: "component---src-templates-user-group-detail-js" */),
  "component---src-templates-user-group-list-js": () => import("./../../../src/Templates/UserGroupList.js" /* webpackChunkName: "component---src-templates-user-group-list-js" */),
  "component---src-templates-works-page-index-js": () => import("./../../../src/Templates/WorksPage/index.js" /* webpackChunkName: "component---src-templates-works-page-index-js" */)
}

