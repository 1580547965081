import React, {useCallback} from 'react';
import styled from 'styled-components';
import LoginForm from './Components/LoginForm';
import CmsDashboard from './Components/Dashboard';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
const appConfig = require('./data.json');

Font.register({
  family: 'MiSans',
  src: '/MiSans-Normal.ttf',
});

Font.registerHyphenationCallback((word) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

const lagacyEntries = [
  'welcome',
  // 'orderList',
  // 'review',
  // 'production',
  // "logistic",
  // "normalInvoice",
  // "manualInvoice",
  // "normal",
  // "enterprise",
  // "reqUpgrade",
  // 'supplier',
  // 'return',
  // 'refund',
];

export default function Layout({children, location}) {
  const [_profile, setProfile] = useOutlet('user');
  const [actions] = useOutlet('actions');
  const [profile] = useOutlet('user');

  const onEntryFunction = useCallback(async (entry) => {
    if (entry.name === 'logout') {
      await actions.logout();
      actions.autoLoginFinish();
      setProfile(null);
      actions.setToken(null);
      return;
    }
    if (lagacyEntries.includes(entry.name)) {
      navigate(`/`);
    } else {
      navigate(`/${entry.name}/`);
    }
  }, []);

  if (!profile) {
    return <LoginForm />;
  } else {
    return (
      <Wrapper>
        <CmsDashboard
          loginProfile={profile}
          themeColor={appConfig.colors.main}
          Header={
            <CustomHeader>
              <img
                src="/images/logo.png"
                width="100%"
                height="auto"
                style={{padding: 10}}
              />
            </CustomHeader>
          }
          location={location}
          entries={[
            {
              name: 'welcome',
              label: '產業資訊',
            },
            {
              name: 'order',
              label: '訂單管理',
              entries: [
                {name: 'statistic', label: '儀表板'},
                {name: 'orders', label: '訂單總覽'},
                {name: 'review', label: '審稿階段'}, //review
                {name: 'production', label: '印製階段'}, //production
                {name: 'logistics', label: '出貨階段'}, //logistic
                {name: 'return_app', label: '售後階段'},
                {name: 'refund', label: '退款階段'},
                // { name: "credit", label: "點數紀錄" }
              ],
            },
            ...(appConfig.alpha
              ? [
                  {
                    name: 'periods',
                    label: '訂閱制委託單',
                  },
                ]
              : []),
            {
              name: 'invoice',
              label: '發票中心',
              entries: [
                {name: 'invoices', label: '一般發票'},
                {name: 'manual_invoices', label: '自建發票'},
              ],
            },
            {
              name: 'member',
              label: '會員中心',
              entries: [
                {name: 'members', label: '會員總覽'},
                // {name: 'enterprise', label: '企業會員'},
                ...(!appConfig.isLiteVersion
                  ? [{name: 'monthly_member', label: '月結會員'}]
                  : []),
                ...(!appConfig.isLiteVersion
                  ? [{name: 'request_upgrade', label: '待審核紀錄'}]
                  : []),
                ...(!appConfig.isLiteVersion
                  ? [{name: 'user_groups', label: '會員群組'}]
                  : []),
              ],
            },
            // {
            //   name: "supplier",
            //   label: "廠商中心"
            // },
            {
              name: 'report',
              label: '報表中心',
            },
            ...(!appConfig.isLiteVersion
              ? [
                  {
                    name: 'promotionManage',
                    label: '促銷中心',
                    entries: [
                      {name: 'promotion/discount', label: '全站打折'},
                      {name: 'promotion/credit_feedback', label: '點數回饋'},
                      {name: 'promotion/bonus_feedback', label: '紅利回饋'},
                      {name: 'promotion/gift_bonus', label: '撒紅利'},
                      {name: 'promotion/threshold_discount', label: '滿額折扣'},
                      {name: 'promotion/logistic_fee', label: '免運'},
                      {name: 'coupon', label: '促銷代碼'},
                    ],
                  },
                  {
                    name: 'blogs',
                    label: '文章管理',
                  },
                ]
              : []),
            {
              name: 'productManagement',
              label: '商品管理',
              entries: [
                {name: 'products', label: '商品管理'},
                {name: 'spec_uploader', label: '上傳商品規格'},
                {name: 'spec_editor', label: '商品規格編輯器'},
                // { name: "spec_dev", label: "測試" },
              ],
            },

            ...(!appConfig.isLiteVersion
              ? [
                  {
                    name: 'promo_items',
                    label: '促銷圖片',
                  },
                ]
              : []),
            ...(['eculture', 'sensationsprint'].includes(appConfig.client)
              ? [
                  {
                    name: 'works',
                    label: 'WORKS',
                  },
                ]
              : []),
            {
              name: 'categories',
              label: '分類管理',
            },
            {
              name: 'logout',
              label: '登出',
              type: 'function',
            },
          ]}
          entryFunctionHandler={onEntryFunction}>
          {({cms}) => {
            if (lagacyEntries.includes(cms.entry.name)) {
              return React.cloneElement(children, {cms});
            } else {
              return children;
            }
          }}
        </CmsDashboard>
      </Wrapper>
    );
  }
}

const CustomHeader = styled.div`
  background-color: #002140;
  padding: 12px 8px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h3 {
    color: white;
  }
`;

const Wrapper = styled.div`
  & .ant-input,
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
  }
  &
    .ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 6px 0 0 6px;
  }
  &
    .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 6px 6px 0;
  }

  & .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 8px;
  }

  & .ant-table-tbody tr.production-order-expand > td {
    padding: 0;
  }
`;
