import {navigate} from 'gatsby';
import {getNewOutlet} from 'reconnect.js';
import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import {ErrHistory, ErrLogin} from './errors';
const appConfig = require('./data.json');
const api = require('./ApiProxy');
const {zip3} = require('./taiwan');

function transToQueryStr(params) {
  let query = '';
  if (typeof params === 'object') {
    query = Object.keys(params)
      .filter((key) => params[key] || params[key] === 0) // has value
      .reduce((e, key, idx) => {
        e = e + `${idx === 0 ? '?' : '&'}${key}=${params[key]}`;
        return e;
      }, '');
  }
  return query;
}

const SpinnerOutlet = getNewOutlet('loading', false, {autoDelete: false});
const UserOutlet = getNewOutlet('user', null, {autoDelete: false});
const ActionOutlet = getNewOutlet('actions', null, {autoDelete: false});
const JwtTokenOutlet = getNewOutlet('jwtToken', null, {autoDelete: false});
const AutoLoginProcessingOutlet = getNewOutlet('autoLoginProcessing', true, {
  autoDelete: false,
});

const Actions = {
  setToken: (token) => {
    api.setToken(token);
  },

  getJwtToken: async () => {
    try {
      let {token} = await api.get({
        path: `/api/user/jwt/`,
      });
      JwtTokenOutlet.update(token);
    } catch (error) {}
  },

  setLoading: (loading) => SpinnerOutlet.update(loading),

  navigate: (nextRoute, options = {loading: true}) => {
    if (options.loading) {
      SpinnerOutlet.update(true);
    }
    navigate(nextRoute);
  },

  getHistories: async (data) => {
    const token = JwtTokenOutlet.getValue();
    // if(!token) {
    //   throw new ErrHistory('找不到憑證，請再試一次');
    // }
    if (token) {
      return api.post({
        path: `${appConfig.endpoint.revJstorageHost}/document/history/find?token=${token}`,
        withHost: true,
        data,
      });
    } else {
      return {
        results: [],
        total: 0,
      };
    }
  },

  getSuppliers: async (params) => {
    return api.get({
      path: `/api/supplier/`,
    });
  },

  login: async function ({username, password}) {
    try {
      let response = await api.post({
        path: '/api/admin/login/',
        data: {username, password},
      });

      api.setToken(response.token);
      AsyncStorage.setItem('token', response.token);
      UserOutlet.update({...response, token: undefined});
      this.getJwtToken();
    } catch (err) {
      api.setToken(null);
      AsyncStorage.removeItem('token');
      console.warn(err);
      throw err;
    }
  },

  autoLogin: async function () {
    try {
      let token = await AsyncStorage.getItem('token');

      console.log('token', token);

      api.setToken(token);
      if (token) {
        let response = await this.getProfile();
        UserOutlet.update(response);
        this.getJwtToken();
      } else {
        throw new ErrLogin('找不到憑證');
      }
    } catch (err) {
      api.setToken(null);
      AsyncStorage.removeItem('token');
      console.warn(err);
      throw err;
    }
  },

  logout: async () => {
    api.setToken(null);
    AsyncStorage.removeItem('id');
    AsyncStorage.removeItem('token');
    UserOutlet.update(null);
    JwtTokenOutlet.update(null);
    AutoLoginProcessingOutlet.update(true);
  },

  autoLoginFinish: async () => {
    AutoLoginProcessingOutlet.update(false);
  },

  //#region statistic
  getStatistics: async (params) => {
    let query = transToQueryStr(params);
    /*
    from,
    to,
    */
    return api.get({
      path: `/api/order/report/${query}`,
    });
  },

  getSalesReport: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `${appConfig.endpoint.productSpecUploadUrl}/sales/list${query}`,
      withHost: true,
    });
  },
  //#endregion statistic

  //#region order
  getOrders: async (params) => {
    /*
    ordering, //sort
    order_type,
    payment_status,
    payment_type,
    payment_condition, //有效訂單（for review order list）
    is_item_accepted, //for production step order list
    is_item_manufacture, //for production step order list
    buyer_type,
    delivery_status,
    is_credits_order,
    from_date,
    to_date,
    buyer,
    search, //display_id and receiver_name and email,
    offset,
    limit,
    no_page = false,
    display_state
    my_assignment
    period_order,
    */
    params = {
      ...params,
      ordering: params.ordering || '-created',
    };
    let query = transToQueryStr(params);

    // must have ordering
    // ordering = `amount` or `created` or ``
    return api.get({
      path: `/api/order/${query}`,
    });
  },

  getOrder: async (id) =>
    api.get({
      path: `/api/order/${id}/`,
    }),

  createExtraOrder: async (data) =>
    api.post({
      path: `/api/order/extra/`,
      data,
    }),

  editOrder: async ({id, ...params}) =>
    api.put({
      path: `/api/order/${id}/`,
      data: params,
    }),

  voidOrder: async ({id, void_reason}) => {
    return api.post({
      path: `/checkout/order/${id}/void/`,
      data: {
        void_reason,
      },
    });
  },

  createCustomOrder: async (data) => {
    return api.post({
      path: `/api/order/custom/`,
      data,
    });
  },

  calcCustomOrder: async (data) => {
    return api.post({
      path: `/api/order/custom/calc/`,
      data,
    });
  },

  //#endregion order

  //#region order_item
  getOrderItems: async (order_id) => {
    return api.get({
      path: `/api/order_item/?order=${order_id}`,
    });
  },

  editOrderItem: async (data) => {
    return api.put({
      path: `/api/order_item/${data.id}/`,
      data,
    });
  },

  addAttatchment: async (data) => {
    return api.post({
      path: `/api/order_item/attachment/`,
      data,
    });
  },

  //#endregion order_item

  //#region logistic
  getLogistics: async (params) => {
    params.ordering = '-updated';
    let query = transToQueryStr(params);

    if (params.order_voided === false) {
      query += '&order_voided=false';
    }

    return api.get({
      path: `/api/logistics/` + query,
    });
  },

  editLogistic: async ({id, ...data}) => {
    return api.put({
      path: `/api/logistics/${id}/`,
      data,
    });
  },

  createLogistic: async (data) => {
    return api.post({
      path: `/api/logistics/`,
      data,
    });
  },
  //#endregion logistic

  //#region invoice
  getInvoices: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `/api/issue/invoice/` + query,
    });
  },

  getInvoice: async (id) => {
    return api.get({
      path: `/api/invoice/${id}/`,
    });
  },

  voidInvoice: async (data) => {
    return api.post({
      path: `/api/void/invoice/`,
      data,
    });
  },

  createInvoice: async ({type, params: data}) => {
    /*
      NORMAL: 0,
      EMTPY_INVOICE: 1
      */
    if (type == 0) {
      // NORMAL
      return api.post({
        path: `/api/issue/order/invoice/`,
        data,
      });
    } else {
      // EMTPY_INVOICE
      return api.post({
        path: `/api/issue/invoice/`,
        data,
      });
    }
  },

  editInvoiceAfterTreatmentState: async (data) => {
    return api.post({
      path: `/api/invoice/after_threatment/`,
      secure: true,
      data,
    });
  },

  //#endregion invoice

  //#region refund
  getRefunds: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      secure: true,
      path: `/api/refund/` + query,
    });
  },

  createRefund: async (data) => {
    return api.post({
      path: `/api/refund/`,
      data,
    });
  },

  editRefund: async (data) => {
    return api.put({
      path: `/api/refund/${data.id}/`,
      data,
    });
  },

  refundToNeweb: async (id) => {
    return api.post({
      path: `/api/staff/refund/${id}/`,
    });
  },
  //#endregion refund

  //#region return_app
  getReturnApps: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/return_app/all/` + query,
    });
  },

  getReturnApp: async (id) => {
    return api.get({
      path: `/api/return_app/${id}`,
    });
  },

  createReturnApp: async (params) => {
    let formData = new FormData();
    for (let key in params) {
      if (params[key] !== undefined && params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    return api.formPost({
      formData,
      path: `/api/return_app/`,
    });
  },

  editReturnApp: async (params) => {
    let formData = new FormData();
    for (let key in params) {
      if (params[key] !== undefined && params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    return api.formPut({
      formData,
      path: `/api/return_app/${params.id}/`,
    });
  },
  //#endregion return_app

  //#region promotion
  getCoupons: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/coupon` + query,
    });
  },

  getCoupon: async (id) => {
    return api.get({
      path: `/api/coupon/${id}`,
    });
  },

  addCoupon: async (data) => {
    return api.post({
      path: `/api/coupon`,
      data,
    });
  },

  editCoupon: async ({id, ...data}) => {
    return api.put({
      path: `/api/coupon/${id}`,
      data,
    });
  },

  editPromotionFeedback: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `/api/promotion/feedback/`,
      data,
    });
  },

  editPromotionBonus: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `/api/promotion/bonus/`,
      data,
    });
  },

  editPromotionDiscount: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `/api/promotion/discount/`,
      data,
    });
  },

  editPromotionBonusGift: async (data) => {
    return api.post({
      path: `/api/promotion/bonus_gift/`,
      data,
    });
  },

  editPromotionFee: async (data) => {
    //BE logic: create a feedback promotion instance & delete previuos
    return api.post({
      path: `/api/promotion/fee/`,
      data,
    });
  },

  addPromotionThreshold: async (data) => {
    return api.post({
      path: `/api/promotion/threshold/`,
      data,
    });
  },

  editPromotionThreshold: async ({id, ...data}) => {
    return api.put({
      path: `/api/promotion/threshold/${id}/`,
      data,
    });
  },

  deletePromotionThreshold: async (id) => {
    return api.delete({
      path: `/api/promotion/threshold/${id}/`,
    });
  },

  // getBonusGiftConfigSetList: async () => {
  //   return api.get({
  //     path: `/api/promotion/bonus_gift/`,
  //   });
  // },

  triggerBonusGift: async (promotionId) => {
    return api.post({
      path: `/api/promotion/bonus_gift/trigger/`,
      data: {
        promotion: promotionId,
      },
    });
  },

  getPromotionDiscount: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/discount/${query}`,
    });
  },

  getPromotionFeedback: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/feedback/${query}`,
    });
  },

  getPromotionBonus: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/bonus/${query}`,
    });
  },

  getPromotionBonusGift: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/bonus_gift/${query}`,
    });
  },

  getPromotionFee: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/fee/${query}`,
    });
  },

  getPromotionThreshold: async (params) => {
    const query = transToQueryStr(params);
    return api.get({
      path: `/api/promotion/threshold/${query}`,
    });
  },

  //#endregion promotion

  //#region promo_item
  getPromoItems: async () => {
    return api.get({
      path: `/api/promo_item/`,
    });
  },

  getPromoItem: async (id) => {
    return api.get({
      path: `/api/promo_item/${id}`,
    });
  },

  // not yet
  editPromoItem: async ({id, imageBase64, ...data}) => {
    if (data.image instanceof File) {
      let formData = new FormData();
      Object.keys(data).forEach((k) => formData.append(k, data[k]));

      return api.formPut({
        path: `/api/promo_item/${id}/`,
        formData,
      });
    } else {
      // if image is not file ,then not bring it
      const {image, ..._data} = data;
      return api.put({
        path: `/api/promo_item/${id}/`,
        data: _data,
      });
    }
  },

  addPromoItem: async ({imageBase64, ...data}) => {
    let formData = new FormData();
    Object.keys(data).forEach((k) => formData.append(k, data[k]));

    return api.formPost({
      path: `/api/promo_item/`,
      formData,
    });
  },

  deletePromoItem: async (id) => {
    return api.delete({
      path: `/api/promo_item/${id}`,
    });
  },

  //#endregion promo_item

  //#region product
  getProducts: async (params) => {
    let query = transToQueryStr(params);
    return api.get({
      path: `/api/staff/product/${query}`,
    });
  },

  getProduct: async (id) => {
    return api.get({
      path: `/api/product/${id}`,
    });
  },

  addProduct: async (_params) => {
    let params = {..._params};
    delete params.imageBase64;
    const exclusions = [
      'image',
      'is_on_shelf',
      'file_upload',
      'related_product',
    ];

    for (let field of exclusions) {
      if (!params[field] && params[field] !== false) {
        delete params[field];
      }
    }

    if (params.image instanceof File) {
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      return api.formPost({
        path: `/api/product/`,
        formData,
        secure: true,
      });
    } else {
      return api.post({
        path: `/api/product/`,
        data: params,
      });
    }
  },

  editProduct: async (_params) => {
    let params = {..._params};
    delete params.imageBase64;
    const exclusions = [
      'image',
      'is_on_shelf',
      'file_upload',
      'related_product',
    ];

    for (let field of exclusions) {
      if (!params[field] && params[field] !== false) {
        delete params[field];
      }
    }

    if (params.image instanceof File) {
      let formData = new FormData();

      for (let key in params) {
        formData.append(key, params[key]);
      }

      return api.formPut({
        path: `/api/product/${_params.id}`,
        formData,
        secure: true,
      });
    } else {
      delete params.image;
      return api.put({
        path: `/api/product/${params.id}`,
        data: params,
      });
    }
  },

  deleteProduct: async (id) => {
    return api.delete({
      path: `/api/staff/product/delete/${id}`,
    });
  },

  getProductImages: async (id) => {
    return api.get({
      path: `/api/product/image?product=${id}`,
    });
  },

  createProductImage: async (data) => {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return api.formPost({
      path: `/api/product/image`,
      formData,
    });
  },

  editProductImage: async (data) => {
    if (data.image) {
      let formData = new FormData();

      for (let key in data) {
        data[key] && formData.append(key, data[key]);
      }

      return api.formPut({
        path: `/api/product/image/${data.id}/`,
        formData,
      });
    } else {
      return api.put({
        path: `/api/product/image/${data.id}/`,
        data,
      });
    }
  },

  deleteProductImage: async (id) => {
    return api.delete({
      path: `/api/product/image/${id}/`,
    });
  },

  getProductFiles: async (id) => {
    return api.get({
      path: `/api/product/file/?product=${id}`,
    });
  },

  createProductFile: async (data) => {
    let formData = new FormData();

    for (let key in data) {
      data[key] && formData.append(key, data[key]);
    }

    return api.formPost({
      path: `/api/product/file/`,
      formData,
    });
  },

  editProductFile: async (data) => {
    if (data.file) {
      let formData = new FormData();

      for (let key in data) {
        data[key] && formData.append(key, data[key]);
      }

      return api.formPut({
        path: `/api/product/file/${data.id}/`,
        formData,
      });
    } else {
      return api.put({
        path: `/api/product/file/${data.id}/`,
        data,
      });
    }
  },

  deleteProductFile: async (id) => {
    return api.delete({
      path: `/api/product/file/${id}/`,
    });
  },

  //#endregion product

  //#region member
  getMembers: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `/api/user/profile/all/${query}`,
    });
  },

  getMember: async (id) => {
    return api.get({
      path: `/api/staff/profile/${id}/`,
    });
  },

  getProfile: async () => {
    return api.get({
      path: `/api/user/profile/`,
    });
  },

  createUser: async ({username, password, staff_type}) => {
    return api.post({
      path: `/auth/user/create`,
      secure: true,
      data: {
        username,
        password,
        staff_type,
      },
    });
  },

  editMember: ({id, ...data}) => {
    return api.put({
      path: `/api/staff/profile/${id}/`,
      data,
    });
  },

  editCreditBonus: ({id, ...data}) => {
    return api.put({
      path: `/api/staff/refund/credits/${id}`,
      data,
    });
  },

  getRequestUpgrades: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `/auth/user/upgrade/list` + query,
    });
  },

  reviewRequestUpgrade: async ({id, ...data}) => {
    return api.put({
      path: `/auth/user/upgrade/${id}/review`,
      data,
    });
  },

  overdueMonthlyMember: async () => {
    return api.get({
      path: `/api/staff/monthly/remind/`,
    });
  },

  getReviewers: async (params) => {
    return api.get({
      path: `/api/staff/management/`,
    });
  },

  assignReviewer: async (data) => {
    return api.post({
      path: `/api/assignee/`,
      data,
    });
  },

  //#endregion member

  //#region address
  getCities: () => {
    return [...new Set(zip3.map((x) => x.city))].map((x) => ({countyName: x}));
    return api.get({
      path: `https://daiwanlang.netlify.app/api/行政區/get`,
      withHost: true,
      secure: false,
    });
  },

  getDistricts: (county) => {
    return zip3
      .filter((x) => x.city === county)
      .map((x) => ({townName: x.district, zipCode: x.zip}));
    return api.get({
      path: `https://daiwanlang.netlify.app/api/行政區/${county}/get`,
      withHost: true,
      secure: false,
    });
  },

  getZipCode: (zipcode) => {
    let result = zip3.find((x) => x.zip === zipcode);
    if (result) {
      return {
        countyName: result.city,
        townName: result.district,
      };
    }

    // return api.get({
    //   path: `https://daiwanlang.netlify.app/api/行政區/郵遞區號/${zipcode}/get`,
    //   withHost: true,
    //   secure: false,
    // })
  },
  //#endregion address

  //#region monthly order
  getMonthlyOrders: (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `/api/staff/monthly/order/${query}`,
    });
  },

  getMonthlyOrder: (id) => {
    return api.get({
      path: `/api/staff/monthly/order/${id}/`,
    });
  },

  editMonthlyOrder: ({id, ...data}) => {
    return api.put({
      path: `/api/staff/monthly/order/${id}/`,
      data,
    });
  },

  settleMonthlyOrder: (data) => {
    return api.post({
      path: `/api/staff/monthly/order/`,
      data,
    });
  },

  createMonthlyInvoice: async (data) => {
    return api.post({
      path: `/api/issue/monthly/order/invoice/`,
      data,
    });
  },

  notifyExpiredMonthly: async (data) => {
    return api.post({
      path: `/api/staff/monthly/order/notify`,
      data,
    });
  },
  //#endregion monthly order

  //#region storage
  getPrivateFile: (key) => {
    return api.post({
      path: `${
        appConfig.endpoint.revStorageHost
      }/storage/read?token=${JwtTokenOutlet.getValue()}`,
      withHost: true,
      secure: false,
      data: {
        filename: key,
      },
    });
  },

  //#endregion storage

  //#region usergroup
  getUserGroups: async (params) => {
    let query = transToQueryStr(params);

    return api.get({
      path: `/auth/user/group` + query,
    });
  },

  getUserGroup: async (id) => {
    return api.get({
      path: `/auth/user/group/${id}`,
    });
  },

  addUserGroup: async (data) => {
    return api.post({
      path: `/auth/user/group`,
      data,
    });
  },

  editUserGroup: async ({id, ...data}) => {
    return api.put({
      path: `/auth/user/group/${id}`,
      data,
    });
  },
  //#endregion

  //#region period
  getPeriods: async (params) => {
    let query = transToQueryStr(params);
    return api.get({
      path: `/api/staff/period/order/${query}`,
    });
  },

  getPeriod: async (id) => {
    return api.get({
      path: `/api/staff/period/order/${id}/`,
    });
  },

  editPeriod: async (data) => {
    return api.put({
      path: `/api/staff/period/order/${data.id}/`,
      data,
    });
  },

  createPeriodExtra: async (data) => {
    return api.post({
      path: `/api/order/extra/period`,
      data,
    });
  },

  terminatePeriod: async (data) => {
    return api.post({
      path: `/checkout/neweb/period/terminate`,
      data,
    });
  },
  //#endregion period

  rebuild: async () => {
    //return  await api.post(`${appConfig.endpoint.netlifyBuildHook}`, {});
    return api.get({
      path: `${
        appConfig.endpoint.productSpecUploadUrl
      }/misc/rebuild?token=${JwtTokenOutlet.getValue()}`,
      withHost: true,
      secure: false,
    });
  },
};
ActionOutlet.update(Actions);
