import './polyfill';
import React, {useContext, useEffect} from 'react';
import Layout from './Layout';
import ActivityIndicator from './Components/ActivityIndicator';
import {message} from 'antd';
import './AppContext';
import './index.css';
import 'antd/dist/antd.css';
const config = require('./data.json');
import ErrorBoundary from './Components/ErrBoundary';
import * as SdkConfig from 'rev.sdk.js/Config';
import {useOutlet} from 'reconnect.js';

SdkConfig.init({
  clientId: config.client,
  stage:
    config.client === 'eculture' ||
    config.client === 'eculture-demo' ||
    config.client === 'sens-stg'
      ? 'stg'
      : 'prod',
  jstoreHost: config.endpoint.revJstorageHost.slice(
    0,
    config.endpoint.revJstorageHost.lastIndexOf('/'),
  ),
  jstoreVersion: config.endpoint.revJstorageHost.slice(
    config.endpoint.revJstorageHost.lastIndexOf('/') + 1,
  ),
  storageHost: config.endpoint.revStorageHost.slice(
    0,
    config.endpoint.revStorageHost.lastIndexOf('/'),
  ),
  storageVersion: config.endpoint.revStorageHost.slice(
    config.endpoint.revStorageHost.lastIndexOf('/') + 1,
  ),
});

if (!config.debug || typeof window === 'undefined') {
  console.log = () => 0;
  console.group = () => 0;
  console.groupEnd = () => 0;
}

export function PageContainer(props) {
  return (
    <WithPageContext {...props}>
      <ErrorBoundary>
        <Layout {...props}>{props.children}</Layout>
      </ErrorBoundary>
    </WithPageContext>
  );
}

function WithPageContext({children}) {
  const [profile] = useOutlet('user');
  const [actions] = useOutlet('actions');

  useEffect(() => {
    (async () => {
      if (!profile) {
        message.loading({
          content: '正在嘗試自動登入',
          key: 'autologin',
        });

        try {
          await actions.autoLogin();
          message.success({
            content: '自動登入成功',
            key: 'autologin',
          });
        } catch (err) {
          message.destroy('autologin');
        }
        actions.autoLoginFinish();
      }
    })();
  }, [profile]);

  return children;
}

export function AppRoot(props) {
  return (
    <div>
      {props.children}
      <ActivityIndicator />
    </div>
  );
}
